<template>
  <div class="head">
    <div v-if="show" class="zhezhao" @click="show = false"></div>
    <div v-if="show" class="open-box">
      <div class="map-card">地图名片</div>
      <div class="map-card-info">
        <div class="title">{{ $store.state.staticName.company }}</div>
        <div class="msg msg1">
          {{ $store.state.staticName.phone }}：400-689-8628
        </div>
        <div class="msg">{{ $store.state.staticName.company_address }}</div>
        <div class="msg">
          {{ $store.state.staticName.email }}：wuyuekeji201781@sina.com
        </div>
        <div class="msg">
          {{ $store.state.staticName.WeChat_Official_Account }}：wykj
        </div>
        <div class="msg">QQ：930338998</div>
      </div>
      <el-image
        :src="require('../assets/map.png')"
        :preview-src-list="[require('../assets/map.png')]"
      >
      </el-image>
    </div>
    <div class="go-top-box">
      <div class="bodybtn1">
        <BackToTop />
        <!--        <span class="iconfont iconlujing371" @click="backtop"></span>-->
      </div>
      <div class="bodybtn2" @click="show = true">
        <span class="iconfont iconzu71"></span>
      </div>
      <div
        class="bodybtn2"
        @click="
          $router.push({
            path: `/wuyue`,
            query: {
              w: '3',
            },
          })
        "
      >
        <span class="iconfont iconzu1"></span>
      </div>
    </div>
    <div class="head-div">
      <img src="@/assets/logo.jpeg" class="head-logo" />
      <div class="head-share">
        <!--        <span class="iconfont iconlujing10"></span>
        <span class="iconfont iconlujing8"></span>
        <span class="iconfont iconlujing9"></span>-->
        <el-select
          v-model="language"
          @change="languageSwitch(language)"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="head-list">
      <div
        v-for="(item, i) in menu"
        :key="'menu' + i"
        @click="toPage(item.path, i)"
        class="head-list-li"
        :class="
          $route.path.indexOf(item.path) !== -1 ||
          ($route.path.indexOf('/titaniumproducts') !== -1 && i === 1)
            ? 'active'
            : ''
        "
      >
        <p>{{ item.name }}</p>
      </div>
      <!--      <div @click="toPage('/Corecustomiza')" class="head-list-li">-->
      <!--        <p>定制化服务</p>-->
      <!--      </div>-->
      <!--      <div @click="toPage('/product')" class="head-list-li">-->
      <!--        <p>产品中心</p>-->
      <!--      </div>-->
      <!--      <div @click="toPage('')" class="head-list-li">-->
      <!--        <p>质量保证 </p>-->
      <!--      </div>-->
      <!--      <div @click="toPage('')" class="head-list-li">-->
      <!--        <p>技术创新</p>-->
      <!--      </div>-->
      <!--      <div @click="toPage('/wuyue')" class="head-list-li">-->
      <!--        <p>走进午跃</p>-->
      <!--      </div>-->
      <!--      <div @click="toPage('/contactus')" class="head-list-li">-->
      <!--        <p>联系我们</p>-->
      <!--      </div>-->
    </div>
    <el-carousel indicator-position="outside" v-if="carouseShow">
      <el-carousel-item v-for="(item, i) in carouselList" :key="'wq' + i">
        <img style="width: 100%; height: 100%" :src="item.advert" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import MyBaiduMap from "@/components/MyBaiduMap";
import { mapGetters } from "vuex";
import lang from "@/assets/json/lang";
import BackToTop from "@/components/BackToTop";
export default {
  name: "Head",
  components: {
    BackToTop,
    MyBaiduMap,
  },
  props: {
    carouseShow: {
      type: Boolean,
      default: true,
    },
    carouseType: {
      type: String,
      default: "HOME",
    },
  },
  data() {
    return {
      show: false,
      carouselList: [],
      menu: [],
      // 语言选项栏
      options: [
        {
          value: "CHINESE",
          label: "中文",
        },
        {
          value: "ENGLISH",
          label: "english",
        },
      ],
      // 语言
      language: "",
    };
  },

  created() {
    this.language = this.$store.state.languageType;

    this.getStaticData();
    this.menu = [
      { id: 1, path: "/Home", name: this.$store.state.staticName.home },
      {
        id: 2,
        path: "/corecustomiza",
        name: this.$store.state.staticName.customized,
      },
      { id: 3, path: "/product", name: this.$store.state.staticName.product },
      { id: 4, path: "/quality", name: this.$store.state.staticName.quality },
      {
        id: 5,
        path: "/innovate",
        name: this.$store.state.staticName.innovation,
      },
      { id: 6, path: "/wuyue", name: this.$store.state.staticName.AboutUs },
      {
        id: 7,
        path: "/contactus",
        name: this.$store.state.staticName.ContactUs,
      },
    ];
    this.getAdData();
  },
  computed: {
    ...mapGetters([
      // 'getAdList',
      "staticName",
    ]),
  },

  methods: {
    getAdData() {
      this.$axios({
        url: "/queryAdvertsByAll",
        method: "get",
        params: {
          type: this.carouseType,
        },
      }).then((res) => {
        this.carouselList = res.data.value;
        // this.$store.dispatch('setAdList', res.data.value);
      });
    },
    getStaticData() {
      if (this.$store.state.languageType === "CHINESE") {
        console.log(lang.Chinese);
        this.$store.dispatch("setStaticName", lang.Chinese);
      } else {
        this.$store.dispatch("setStaticName", lang.English);
      }
    },
    languageSwitch(data) {
      this.$store.dispatch("setLanguageType", data);
      this.getStaticData();
      this.menu = [
        { id: 1, path: "/Home", name: this.$store.state.staticName.home },
        {
          id: 2,
          path: "/corecustomiza",
          name: this.$store.state.staticName.customized,
        },
        { id: 3, path: "/product", name: this.$store.state.staticName.product },
        { id: 4, path: "/quality", name: this.$store.state.staticName.quality },
        {
          id: 5,
          path: "/innovate",
          name: this.$store.state.staticName.innovation,
        },
        { id: 6, path: "/wuyue", name: this.$store.state.staticName.AboutUs },
        {
          id: 7,
          path: "/contactus",
          name: this.$store.state.staticName.ContactUs,
        },
      ];
    },
    toPage(url, index) {
      if (url === "") {
        this.$message("暂未开放");
        return false;
      }
      this.$router.push({ path: url });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
:deep(.el-carousel__indicators--horizontal) {
  left: 47.4%;
}
:deep(.el-carousel__container) {
  height: 180px;
}
:deep(.el-carousel__button) {
  width: 16px !important;
  height: 16px !important;
}
:deep(.el-carousel__indicators) {
  position: absolute;
  bottom: 30px;
  z-index: 99;
}
:deep(.el-carousel__button) {
  width: 7px;
  height: 7px;
  border-radius: 15px;
  margin-right: 15px;
  background: #ffffff;
  opacity: 1;
}
:deep(.el-carousel__indicator.is-active button) {
  background: #1850af;
}
//width: 700px;
//bottom: 20rem;
//right: 64rem;
.active {
  background-color: white !important;

  p {
    color: #1850af !important;
  }
}
.head {
  display: flex;
  flex-direction: column;
  .zhezhao {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 99998;
  }
  .open-box {
    //width: 500px;
    //height: 500px;
    position: fixed;
    bottom: 100px;
    right: 64px;
    z-index: 99999;
    //background: #999999;
    .map-card {
      text-align: center;
      padding-top: 7px;
      padding-bottom: 7px;
      font-size: 26px;
      background-color: #f2f2f2;
      color: #808080;
      box-shadow: 0 3rem 6rem 0 rgba(0, 0, 0, 0.16);
      margin-bottom: 10px;
    }
    .map-card-info {
      background-color: #f2f2f2;
      color: #808080;
      box-shadow: 0 3rem 6rem 0 rgba(0, 0, 0, 0.16);
      padding-top: 12px;
      div {
        padding-left: 6px;

        font-size: 18px;
        margin-top: 10px;
      }
    }
  }
  .go-top-box {
    position: fixed;
    bottom: 537px;
    right: 0px;
    color: #ffffff;

    .bodybtn1 {
      width: 61px;
      height: 61px;
      //background: #b3b3b3;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 30px;
      }
    }
    .bodybtn2 {
      margin-top: 4px;
      color: #ffffff;
      width: 61px;
      height: 61px;
      background: #b3b3b3;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 30px;
      }
    }
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  :deep(.el-carousel__container) {
    width: 1920px;
    height: 858px;
  }

  .head-div {
    display: flex;
    justify-content: space-between;

    .head-logo {
      width: 120px;
      height: 84px;
      margin-left: 130px;
    }

    .head-share {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 130px;

      span::before {
        font-size: 24px;
        color: #1850af;
        margin-right: 20px;
      }
    }
  }

  .head-list {
    display: flex;

    width: calc(100% - 130px - 130px);
    height: 68px;
    background-color: #1850af;
    flex-direction: row;
    padding: {
      left: 130px;
      right: 130px;
    }

    .head-list-li {
      flex-grow: 1;
      text-align: center;

      p {
        margin: 0;
        font-size: 24px;
        color: white;
        line-height: 68px;
      }
    }

    .head-list-li:hover {
      cursor: pointer;
      background-color: white;

      p {
        color: #1850af;
      }
    }
  }
}
</style>
