<template>
  <div class="product" v-if="reFresh">
    <Head carouseType="PRODUCT" :carouseShow="!showOne"></Head>

    <div class="product-titles">
      <p class="product-title">{{ $store.state.staticName.product }}</p>
    </div>
    <div class="product-main">
      <h1>{{ $store.state.staticName.classify }}</h1>
      <div class="product-main-box">
        <!--        产品分类-->
        <ul class="product-main-box-left" id="globalNav">
          <li class="main_nav" v-for="(item, i) in fenLe" :key="'nav' + i">
            <div class="nav-one">
              <a href="javascript:;">{{ item.name }}</a>
              <span
                class="icon iconfont iconduobianxing201"
                style="display: none"
              ></span>
              <span
                class="icon iconfont iconduobianxing2"
                style="display: none"
              ></span>
            </div>
            <ul>
              <li
                v-for="(it, i1) in item.children"
                :key="'m' + i1"
                @click="toFenle(it.id)"
                :data="it.id"
              >
                <a
                  class="sub-category-desc"
                  :class="it.id === fenleId ? 'active' : ''"
                  href="javascript:;"
                >
                  {{ it.name }}
                </a>
              </li>
            </ul>
          </li>
          <!--          <li class="main_nav">-->
          <!--            <div class="nav-one">-->
          <!--              <a href="#">房间信息</a>-->
          <!--              <span class="iconfont iconduobianxing20"></span>-->
          <!--            </div>-->
          <!--            <ul>-->
          <!--              <li><a href="nullroom.php" target="mainFrame">空房</a></li>-->
          <!--              <li><a href="room.php" target="mainFrame">所有房间</a></li>-->
          <!--              <li><a href="orderroom.php" target="mainFrame">被订房间</a></li>-->
          <!--              <li><a href="register/room.html" target="mainFrame">录入房间信息</a></li>-->
          <!--            </ul>-->
          <!--          </li>-->
        </ul>
        <!--        产品-->
        <div class="product-main-box-right" v-if="!showOne" v-loading="loading">
          <div class="card-box">
            <el-card
              :body-style="{ padding: '0px' }"
              v-for="(item, i) in shopList"
              :key="'i' + i"
              class="product-card"
            >
              <img
                :src="item.tradeBanner"
                @click="toDetail(item.id)"
                class="image"
              />
              <div>
                <span>{{ item.tradeName }}</span>
              </div>
            </el-card>
          </div>
          <new-pagination
            @current-change="currentChange"
            :page-size="page.pageSize"
            layout="prev, pager, next"
            :total="total"
          ></new-pagination>
          <!--          <el-pagination-->
          <!--              align="center"-->
          <!--              @current-change="currentChange"-->
          <!--              :page-size="page.pageSize"-->
          <!--              layout="prev, pager, next"-->
          <!--              :total="total">-->
          <!--          </el-pagination>-->
        </div>
        <div class="product-main-box-right" v-if="showOne" v-loading="loading">
          <div class="product-xiangqin-h">
            <div class="product-xiangqin">
              <div class="img-box">
                <img class="img-box" :src="imgUrl" />
              </div>
              <div class="xiangqin-right">
                <div class="xiangqin-header">
                  <div class="xiangqin-name">
                    {{ detail.tradeName }}
                    <i @click="close" class="close el-icon-circle-close"></i>
                  </div>
                  <div class="xiangqin-jieshao">{{ detail.describe }}</div>
                </div>
                <div class="xiangqin-bottom">
                  <div class="xiangqin-bottom-left">
                    <div class="xiangqin-qian">
                      ￥{{ childInfo.tradePrice }}
                    </div>
                    <div class="xiangqin-lianxi">
                      {{ $store.state.staticName.ContactHotline }}：0812-555555
                    </div>
                    <div class="xiangqin-lianxi">
                      {{ $store.state.staticName.Contactservice }}QQ：2248767
                    </div>
                  </div>
                  <div class="xiangqin-bottom-right">
                    <div class="buy-img">
                      <img
                        class="buy-img"
                        :src="`${this.baseUrl}/createLogoQRCode?id=${
                          detail.id ? detail.id : $route.query.i
                        }`"
                      />
                    </div>
                    <div class="buy-text">
                      {{ $store.state.staticName.Clickscan }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-xiangqin-size">
            <div
              @click="updateImg(it)"
              v-for="(it, i) in detail.children"
              :key="'q' + i"
              :class="active === it.id ? 'active' : ''"
            >
              {{ it.tradeName }}
            </div>
          </div>
          <div class="detail-info-box" v-if="showOne && childInfo">
            <div class="introduce-box">
              <div class="title">
                {{ $store.state.staticName.Productintroduction }}:
              </div>
              <div class="detail-text">
                {{ childInfo.introduce }}
              </div>
            </div>

            <!-- <div class="introduce-box">
              <div class="title">
                {{ $store.state.staticName.Customizedcustomers }}:
              </div>
              <div class="detail-text">
                {{ childInfo.custom }}
              </div>
            </div>

            <div class="introduce-box">
              <div class="title">{{ $store.state.staticName.Usepurpose }}:</div>
              <div class="detail-text">
                {{ childInfo.purpose }}
              </div>
            </div>

            <div class="introduce-box">
              <div class="title">
                {{ $store.state.staticName.Productsize }}:
              </div>
              <div class="detail-text">
                {{ childInfo.size }}
              </div>
            </div>

            <div class="introduce-box">
              <div class="title">
                {{ $store.state.staticName.Productmaterial }}:
              </div>
              <div class="detail-text">
                {{ childInfo.texture }}
              </div>
            </div>

            <div class="introduce-box">
              <div class="title">
                {{ $store.state.staticName.Productprocess }}:
              </div>
              <div class="detail-text">
                {{ childInfo.craft }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from "@/components/Head";
import Foot from "@/components/Foot";
import { mapState } from "vuex";

export default {
  name: "Product",
  components: {
    Head,
    Foot,
  },
  data() {
    return {
      loading: false,
      showOne: false,
      fenLe: [],
      shopList: [],
      childInfo: [],
      total: 0,
      page: {
        currentPage: 1,
        pageSize: 20,
        input: 0,
      },
      imgUrl: "",
      detail: [],
      fenleId: 0,
      active: 0,
      detailId: undefined,
    };
  },
  created() {
    this.reFresh();
  },
  watch: {
    "$route.query.c": {
      handler(newVal) {
        this.reFresh();
        // this.fenleId = newVal
      },
    },
    "$store.state.languageType": {
      handler(newVal) {
        let this1 = this;
        this.loading = true;
        this.getFenle();
        let fenleId = this.fenleId;
        if (this.detailId && this.showOne) {
          this.toDetail(this.detailId);

          let t = setTimeout(() => {
            this1.loading = false;
            this1.showOne = true;
            clearTimeout(t);
          }, 80);
        }

        let t = setTimeout(() => {
          this1.fenleId = fenleId;
          this1.loading = false;
          this1.toFenle(fenleId);
          clearTimeout(t);
        }, 70);
      },
      // deep: true // 深度监听
    },
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    }),
  },
  mounted() {
    this.initTree("globalNav");
  },
  methods: {
    reFresh() {
      let classify = this.$route.query.c;
      let product = this.$route.query.i;
      // 赋值当前分类

      this.getFenle(classify);
      // 查询详情
      if (product) {
        let this1 = this;
        let t = setTimeout(() => {
          this1.showOne = true;
          clearTimeout(t);
        }, 150);

        this.toDetail(product);
      }
    },
    close() {
      this.showOne = false;
    },
    updateImg(item) {
      this.active = item.id;
      this.imgUrl = item.tradePreview[0];
      this.childInfo = item;
    },
    toDetail(id) {
      this.detailId = id;
      this.loading = true;
      this.$axios({
        method: "GET",
        url: "/queryCommodityOne",
        params: {
          id: id,
          type: this.languageType,
        },
      })
        .then((res) => {
          if (res.data.status == "200") {
            this.showOne = true;
            this.detail = res.data.value;

            if (res.data.value.children.length > 0) {
              this.active = res.data.value.children[0].id;
              this.imgUrl = res.data.value.children[0].tradePreview[0];
            } else {
              this.imgUrl = res.data.value.tradeBanner[0];
            }
            this.childInfo = res.data.value.children[0];

            this.loading = false;
          } else {
            this.$message({
              message: res.data.message,
              type: "warning",
              duration: 4000,
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    toFenle(i) {
      this.showOne = false;
      if (isNaN(i)) return;
      this.fenleId = i;
      this.page.input = i;
      this.$axios({
        method: "GET",
        url: "/queryCommodityByClassify",
        params: {
          currentPage: this.page.currentPage,
          pageSize: this.page.pageSize,
          input: i,
          type: this.languageType,
        },
      }).then((res) => {
        // this.fenLe = res.data.value;
        this.shopList = res.data.value.value;
        this.total = res.data.value.total;
      });
    },
    getFenle(classify) {
      this.$axios({
        method: "GET",
        url: "/queryClassifyByMenu",
        params: {
          type: this.languageType,
        },
      }).then((res) => {
        this.fenLe = res.data.value ? res.data.value : 0;
        if (!classify && res.data.value.length > 0) {
          this.fenleId = res.data.value[0].children[0].id;
        } else {
          this.fenleId = parseInt(classify);
        }
        this.toFenle(this.fenleId);
      });
    },
    initTree(t) {
      let that = this;
      let e1 = setTimeout(function () {
        let tree = document.getElementById(t); //生成树
        tree.style.display = "none"; //设置样式为隐藏形式，不显示
        let lis = tree.getElementsByTagName("li");
        for (let i = 0; i < lis.length; i++) {
          //取出主要的导航栏
          lis[i].id = "li" + i;
          let uls = lis[i].getElementsByTagName("ul"); //子导航栏
          let active = false;
          if (uls[0] !== undefined && uls[0].children !== undefined) {
            for (let j = 0; j < uls[0].children.length; j++) {
              let value = uls[0].children[j].attributes.data.value;
              if (value === that.fenleId.toString()) {
                active = true;
              }
            }
          }
          if (uls.length !== 0) {
            uls[0].id = "ul" + i;
            if (active) {
              uls[0].style.display = "block";
              uls[0].parentElement.children[0].children[2].style.display =
                "block";
            } else {
              uls[0].style.display = "none";
              uls[0].parentElement.children[0].children[1].style.display =
                "block";
            }
            let as = lis[i].getElementsByTagName("a"); //子导航栏的内容
            as[0].id = "a" + i;
            as[0].className = "folder";
            as[0].href = "javascript:;";
            as[0].tget = uls[0];
            let mannav = lis[i].getElementsByClassName("nav-one");
            mannav[0].onclick = () => {
              that.openTag(as[0], as[0].tget);
            };
          }
        }
        //}
        tree.style.display = "block";
        clearTimeout(e1);
      }, 100);
    },
    openTag(a, t) {
      if (t.style.display === "block") {
        //点击一些展开，再点击一下折叠
        a.parentElement.children[1].style.display = "block";
        a.parentElement.children[2].style.display = "none";
        t.style.display = "none";
        a.className = "folder";
      } else {
        a.parentElement.children[1].style.display = "none";
        a.parentElement.children[2].style.display = "block";
        t.style.display = "block";
        a.className = "";
      }
    },
    currentChange(val) {
      this.page.currentPage = val;
      this.toFenle(this.fenleId);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.active {
  background-color: #1850af !important;
  color: #ffffff !important;
}
.sub-category-desc {
  /*文本强制不换行*/
  white-space: nowrap;
  /*文本溢出显示省略号*/
  text-overflow: ellipsis;
  /*溢出的部分隐藏*/
  overflow: hidden;
}
.product {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .product-titles {
    //margin-top: 74rem;
    //margin-left: 130rem;
    //margin-right: 130rem;
    margin: {
      top: 74px;
      left: 130px;
      right: 130px;
      //bottom: 90px;
    }
    text-align: center;

    .product-title {
      color: #4d4d4d;
      font-size: 50px;
    }
  }

  .product-main {
    margin: 0 120px 95px 130px;
    width: 1670px;
    //height: 500px;
    //background-color: #99a9bf;

    h1 {
      font-size: 30px;
      font-weight: 500;
      text-align: left;
      color: #4d4d4d;
    }

    .product-main-box {
      display: flex;

      //justify-content: space-between;

      .product-main-box-left {
        //background-color: #123231;
        //position: relative;
        box-sizing: border-box;
        width: 270px;
      }

      .product-main-box-right {
        box-sizing: border-box;
        min-height: 800px;
        //详情
        .detail-info-box {
          //margin-left: 380px;
          margin-top: 30px;
          margin-left: 92px;

          .introduce-box {
            width: 1296px;
            min-height: 40px;
            display: flex;
            margin-bottom: 57px;

            .title {
              min-width: 110px;
              font-size: 25px;
              font-weight: 700;
              text-align: left;
              color: #4d4d4d;
              white-space: nowrap;
              //overflow: hidden;
              text-overflow: ellipsis;
            }

            .detail-text {
              //width: 1166px;
              min-height: 40px;
              font-size: 25px;
              font-weight: 400;
              text-align: left;
              color: #666666;
            }
          }
        }

        .product-xiangqin-h {
          box-sizing: border-box;
          min-width: 1276px;
          height: 676px;
          //width: calc(1670px - 270px);
          margin-top: 24px;
          margin-left: 92px;
          background: #f8f8f8;
          border-radius: 5px;
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

          .product-xiangqin {
            box-sizing: border-box;
            min-width: 1276px;
            height: 676px;
            display: flex;

            .img-box {
              box-sizing: border-box;
              width: 676px;
              height: 676px;
              background: #d1d1d1;
              border-radius: 5px;
              box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            }

            .xiangqin-right {
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .xiangqin-header {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 602px;

                .xiangqin-name {
                  position: relative;
                  margin-top: 45px;
                  font-size: 48px;
                  font-weight: 400;
                  text-align: left;
                  color: #666666;
                  // display: flex;
                  // justify-content: space-between;
                  // justify-items: center;

                  // div {
                  //   width: 240px;
                  //   overflow: hidden;
                  //   white-space: nowrap;
                  //   text-overflow: ellipsis;
                  // }
                  .close:hover {
                    cursor: pointer;
                  }

                  .close {
                    position: absolute;
                    right: -200px;
                    top: -28px;
                  }
                }

                .xiangqin-jieshao {
                  margin-top: 45px;
                  width: 457px;
                  font-size: 20px;
                  font-weight: 400;
                  text-align: left;
                  color: #666666;
                  word-break: break-all;
                  display: -webkit-box; /**对象作为伸缩盒子模型展示**/
                  -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
                  -webkit-line-clamp: 7; /**显示的行数**/
                  overflow: hidden; /**隐藏超出的内容**/
                }
              }

              .xiangqin-bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: 34px;
                margin-right: 55px;
                margin-bottom: 20px;

                .xiangqin-bottom-left {
                  .xiangqin-qian {
                    font-size: 68px;
                    font-weight: 400;
                    text-align: left;
                    color: #333333;
                  }

                  .xiangqin-lianxi {
                    font-size: 25px;
                    font-weight: 300;
                    text-align: left;
                    color: #666666;
                  }
                }

                .xiangqin-bottom-right {
                  .buy-img {
                    width: 141px;
                    height: 131px;
                    background: #ffffff;
                    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
                  }

                  .buy-text {
                    margin-top: 16px;
                    width: 141px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 400;
                    color: #4d4d4d;
                  }
                }
              }
            }
          }
        }

        .product-xiangqin-size {
          box-sizing: border-box;
          width: 1276rem;
          margin-top: 24rem;
          margin-left: 92rem;
          display: flex;
          flex-wrap: wrap;
          div:hover {
            cursor: pointer;
          }

          div {
            border: 3px solid #1850af;
            border-radius: 18px;
            padding-left: 20px;
            padding-right: 20px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 30px;
            margin-left: 20px;
            margin-top: 15px;
          }

          // div:first-child {
          //   margin-left: 0px;
          // }
        }

        .card-box {
          display: grid;
          //grid-template-columns: repeat(auto-fill, 337px);
          grid-template-columns: 346px 346px 346px 346px;
          grid-row-gap: 38px;
          //grid-column-gap: 46px;
          flex-wrap: wrap;
          //justify-content: space-around;
          box-sizing: border-box;
          //position: relative;
          width: 1276px;
          //width: calc(1670px - 270px);
          //background-color: #a1e812;
          margin-top: 67px;
          margin-left: 52px;

          .product-card {
            //padding: 6px 9px;
            width: 300px;
            height: 350px;
            background: #fcfcfc;
            border-radius: 5px;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

            .image {
              width: 300px;
              height: 300px;
              background: #b4b4b4;
              border-radius: 5px;
            }
          }

          div {
            width: 100%;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-size: 20px;
              font-weight: 400;
              text-align: left;
              color: #4d4d4d;
            }
          }
        }
      }
    }
  }
}

:deep(.el-carousel__container),
my-carousel {
  height: 580px !important;
}

/*左导航*/
/*设置导航每个主菜单的样式*/
.main_nav {
  width: 270px;
  display: block;
  box-sizing: border-box;
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  color: #4d4d4d;
}

li {
  list-style: none;
}

.nav-one:hover {
  cursor: pointer;
}

.nav-one {
  width: 270px;
  height: 42px;
  padding: 0 15px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
  justify-content: space-between;

  a {
    color: #4d4d4d;
    text-decoration: none;
  }
  .icon:before {
  }
  .icon {
    font-size: 10px !important;
    color: #666666;
  }
}

#globalNav {
}

/*导航中每个子菜单的样式*/
#globalNav ul li a {
  box-sizing: border-box;
  padding-left: 15px;
  width: 206px;
  height: 46px;
  background: #f2f2f2;
  margin-top: 14px;
  margin-left: 28px;
  display: block;
  text-decoration: none;
  font-size: 30px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: left;
  color: #4d4d4d;
}

#globalNav li ul li a {
}

#globalNav li ul li a:hover {
  // background: #BCF2F0; /*鼠标经过的颜色*/
}

.el-pagination {
  margin-top: 30px;
}
</style>
