var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[(_vm.show)?_c('div',{staticClass:"zhezhao",on:{"click":function($event){_vm.show = false}}}):_vm._e(),(_vm.show)?_c('div',{staticClass:"open-box"},[_c('div',{staticClass:"map-card"},[_vm._v("地图名片")]),_c('div',{staticClass:"map-card-info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$store.state.staticName.company))]),_c('div',{staticClass:"msg msg1"},[_vm._v(" "+_vm._s(_vm.$store.state.staticName.phone)+"：400-689-8628 ")]),_c('div',{staticClass:"msg"},[_vm._v(_vm._s(_vm.$store.state.staticName.company_address))]),_c('div',{staticClass:"msg"},[_vm._v(" "+_vm._s(_vm.$store.state.staticName.email)+"：wuyuekeji201781@sina.com ")]),_c('div',{staticClass:"msg"},[_vm._v(" "+_vm._s(_vm.$store.state.staticName.WeChat_Official_Account)+"：wykj ")]),_c('div',{staticClass:"msg"},[_vm._v("QQ：930338998")])]),_c('el-image',{attrs:{"src":require('../assets/map.png'),"preview-src-list":[require('../assets/map.png')]}})],1):_vm._e(),_c('div',{staticClass:"go-top-box"},[_c('div',{staticClass:"bodybtn1"},[_c('BackToTop')],1),_c('div',{staticClass:"bodybtn2",on:{"click":function($event){_vm.show = true}}},[_c('span',{staticClass:"iconfont iconzu71"})]),_c('div',{staticClass:"bodybtn2",on:{"click":function($event){return _vm.$router.push({
          path: `/wuyue`,
          query: {
            w: '3',
          },
        })}}},[_c('span',{staticClass:"iconfont iconzu1"})])]),_c('div',{staticClass:"head-div"},[_c('img',{staticClass:"head-logo",attrs:{"src":require("@/assets/logo.jpeg")}}),_c('div',{staticClass:"head-share"},[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":function($event){return _vm.languageSwitch(_vm.language)}},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_c('div',{staticClass:"head-list"},_vm._l((_vm.menu),function(item,i){return _c('div',{key:'menu' + i,staticClass:"head-list-li",class:_vm.$route.path.indexOf(item.path) !== -1 ||
        (_vm.$route.path.indexOf('/titaniumproducts') !== -1 && i === 1)
          ? 'active'
          : '',on:{"click":function($event){return _vm.toPage(item.path, i)}}},[_c('p',[_vm._v(_vm._s(item.name))])])}),0),(_vm.carouseShow)?_c('el-carousel',{attrs:{"indicator-position":"outside"}},_vm._l((_vm.carouselList),function(item,i){return _c('el-carousel-item',{key:'wq' + i},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.advert}})])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }