<template>
  <div class="foot">
    <!--脚部外部壳子-->
    <div class="foot-box">
      <div class="left-box">
        <div class="left-menu">
          <div class="top-title" @click="toPage('/Home')">
            {{ $store.state.staticName.home }}
          </div>
          <div class="bottom-title" @click="toPage('/Corecustomiza')">
            {{ $store.state.staticName.customized }}
          </div>
          <!--            工业配件定制-->
          <!-- <div class="menu-list">
            <div
              class="menu-item"
              v-for="(item, i) in customized"
              @click="goDetail({ path: `/titaniumproducts/${item.id}` })"
              :key="'item1' + i"
            >
              {{ item.name }}
              
            </div>
          </div> -->
        </div>
        <div class="left-menu">
          <div class="top-title" @click="toPage('/innovate')">
            {{ $store.state.staticName.innovation }}
          </div>
          <div class="bottom-title" @click="toPage('/product')">
            {{ $store.state.staticName.product }}
          </div>
          <!-- <div class="menu-list">
            <div
              class="menu-item"
              v-for="(item, i) in product"
              :key="'qe' + i"
              @click="
                goDetail({
                  path: `/product`,
                  query: {
                    c: item.id,
                  },
                })
              "
            >
              {{ item.name }}
            </div>
          </div> -->
        </div>
        <div class="left-menu">
          <div class="top-title" @click="toPage('/contactus')">
            {{ $store.state.staticName.ContactUs }}
          </div>
          <div class="bottom-title" @click="toPage('/quality')">
            {{ $store.state.staticName.quality }}
          </div>
          <!--            锻造设备-->
          <!-- <div class="menu-list">
            <div
              class="menu-item"
              v-for="(item, i) in quality"
              :key="'ewrw' + i"
              @click="
                goDetail({
                  path: `/quality`,
                  query: {
                    id: item.id,
                  },
                })
              "
            >
              
              {{ item.title }}
            </div>
          </div> -->
        </div>
        <div class="left-menu">
          <div class="top-title"></div>
          <div class="bottom-title" @click="toPage('/wuyue')">
            {{ $store.state.staticName.AboutUs }}
          </div>
          <div class="menu-list">
            <!-- <div
              class="menu-item"
              @click="
                goDetail({
                  path: `/wuyue`,
                  query: {
                    w: '0',
                  },
                })
              "
            >
              {{ $store.state.staticName.company_profiles }}
            </div>
            <div
              class="menu-item"
              @click="
                goDetail({
                  path: `/wuyue`,
                  query: {
                    w: '1',
                  },
                })
              "
            >
              {{ $store.state.staticName.glories }}
            </div>
            <div
              class="menu-item"
              @click="
                goDetail({
                  path: `/wuyue`,
                  query: {
                    w: '2',
                  },
                })
              "
            >
              {{ $store.state.staticName.client }}
            </div>
            <div
              class="menu-item"
              @click="
                goDetail({
                  path: `/wuyue`,
                  query: {
                    w: '3',
                  },
                })
              "
            >
              {{ $store.state.staticName.company_photo }}
            </div>
            <div
              class="menu-item"
              @click="
                goDetail({
                  path: `/wuyue`,
                  query: {
                    w: '4',
                  },
                })
              "
            >
              {{ $store.state.staticName.video }}
            </div> -->
          </div>
        </div>
      </div>
      <div class="xian"></div>
      <div class="right-box">
        <div class="right-info">
          <div class="right-info-box">
            <div class="top-info-box">
              <div class="title">
                <i class="icon iconfont iconlujing52"></i>&nbsp;&nbsp;<span>{{
                  $store.state.staticName.Hotline
                }}</span>
              </div>
              <div class="phone text">400-689-8628</div>
              <div class="date text">
                {{ $store.state.staticName.service_date }}：{{
                  $store.state.staticName.zhou
                }}
                9：00-18：00 {{ $store.state.staticName.xiu }}
              </div>
              <div class="qq text">QQ:930338998</div>
            </div>
          </div>
          <div class="right-info-box">
            <div class="top-info-box">
              <div class="title">
                <span>{{ $store.state.staticName.ContactUs }}</span>
              </div>
              <div class="phone text">
                {{ $store.state.staticName.company_address }}
              </div>
              <div class="date text">
                {{ $store.state.staticName.postal_code }}：617068
              </div>
              <div class="qq text">
                {{ $store.state.staticName.email }}：wuyuekeji201781@sina.com
              </div>
            </div>
          </div>
        </div>
        <div class="code-box">
          <div class="code-gzh-box">
            <div class="code-gzh">
              <img class="img1" src="../assets/gongzhonggao.jpg" alt="" />
            </div>
          </div>
          <div class="text">
            {{ $store.state.staticName.WeChat_Official_Account }}
          </div>
          <div class="code-xcx-box">
            <div class="code-xcx">
              <img class="img1" src="../assets/applet.jpeg" alt="" />
            </div>
          </div>
          <div class="text">{{ $store.state.staticName.WeChat_app }}</div>
        </div>
      </div>
    </div>
    <div class="foot-xian"></div>
    <div class="foot-copy">
      <a href="http://beian.miit.gov.cn" target="_blank"
        >备案号：蜀ICP备2023008831号-1</a
      >
      &nbsp;&nbsp;&nbsp;工信部查询网站：<a
        href="https://beian.miit.gov.cn"
        rel="noopener"
        target="_blank"
        style="
          outline: none;
          cursor: pointer;
          color: #00a2ca;
          line-height: 22px;
        "
        microsoft="background-color:#ffffff;"
        >www.b<wbr />eian.miit.go<wbr />v.cn</a
      >
      <p></p>
      Copyright © 2023 攀枝花市午跃科技有限公司 版权所有 <br />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Foot",
  data() {
    return {
      quality: [],
      customized: [],
      product: [],
    };
  },
  created() {
    this.getData();
  },
  watch: {
    "$store.state.languageType": {
      handler(newVal) {
        // console.log(newVal)
        this.getData();
      },
      // deep: true // 深度监听
    },
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    }),
  },
  methods: {
    toPage(url) {
      //console.log(url)
      if (url === "") {
        this.$message("暂未开放");
        return false;
      }
      this.$router.push({ path: url });
    },
    goDetail(path) {
      this.$router.push({ ...path });
    },
    getData() {
      this.$axios({
        url: "/queryFootNavBarAll",
        method: "get",
        params: {
          type: this.languageType,
        },
      })
        .then((res) => {
          this.product = res.data.value.product;
          this.customized = res.data.value.customized;
          this.quality = res.data.value.quality;
          // console.log(res)
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.foot {
  background: #1850af;

  .foot-box {
    width: 1920px;
    height: 508px;

    display: flex;

    .left-box {
      display: flex;

      .left-menu {
        margin-top: 51px;
        margin-left: 100px;
        width: 140px;

        .top-title:hover {
          cursor: pointer;
        }

        .top-title {
          width: 140px;
          height: 36px;
          text-align: center;
          font-size: 25px;
          font-weight: 500;
          color: #ffffff;
        }

        .bottom-title:hover {
          cursor: pointer;
        }

        .bottom-title {
          margin-top: 49px;
          width: 140px;
          text-align: center;
          font-size: 25px;
          font-weight: 500;
          color: #ffffff;
        }

        .menu-list {
          width: 140px;
          margin-top: 17px;
          text-align: center;

          .menu-item {
            font-size: 16px;
            font-weight: 300;
            color: #ffffff;
            margin-bottom: 27px;
            cursor: pointer;
          }

          .menu-item:hover {
            color: #999999;
          }
        }
      }
    }

    .xian {
      margin: {
        left: 72px;
        top: 124px;
      }
      width: 1px;
      height: 261px;
      border-left: 1px solid #ffffff;
    }

    .right-box {
      display: flex;

      .right-info-box {
        margin-top: 51px;
        margin-left: 88px;

        .icon {
          font-size: 30px;
        }

        .title {
          font-size: 24px;
          font-weight: 600;
          text-align: left;
          color: #ffffff;
        }

        .text {
          width: 390px;
          margin-top: 21px;
          font-size: 20px;
          font-weight: 500;
          text-align: left;
          color: #ffffff;
        }
      }

      .code-box {
        width: 220px;
        margin-left: 80px;
        .code-gzh-box {
          margin-top: 51px;
          width: 280px;
          height: 170px;
          text-align: center;
          .code-gzh {
            margin: 0 auto;
            width: 170px;
            height: 170px;
            background: #ffffff;
          }
        }

        .code-xcx-box {
          margin-top: 38px;
          width: 280px;
          height: 170px;
          text-align: center;
          .code-xcx {
            margin: 0 auto;
            width: 170px;
            height: 170px;
            background: #ffffff;
          }
        }

        .text {
          white-space: nowrap;
          margin-top: 5px;
          width: 290px;
          text-align: center;
          font-size: 24px;
          color: #ffffff;
        }
      }
    }
  }

  .foot-xian {
    margin-top: 100px;
    height: 1px;
    background-color: #ffffff;
    //border: 1px solid #ffffff;
  }

  .foot-copy {
    margin: 20px auto 0;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
  }
}
.img1 {
  width: 100%;
  height: 100%;
}
</style>
